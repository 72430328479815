/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.component.page-selector {
  background: #eaebeb;
  padding: 0em 3.8em 19.2em;
}

@media (max-width: 1199px) {
  .component.page-selector {
    padding: 0em 3.8em 5.2em;
  }
}

.component.page-selector .page-selector-list {
  display: flex;
}

.component.page-selector .page-selector-list a {
  font-size: 2.4em;
  color: #55565b;
  font-weight: 300;
  padding: 0 0.55em;
}

.component.page-selector .page-selector-list a.active {
  color: #2f251c;
}

.component.page-selector .page-selector-list .inactive {
  opacity: 0.5;
}

.component.page-selector .page-selector-item-first,
.component.page-selector .page-selector-item-last {
  display: none;
}

.component.page-selector .page-selector-item-previous,
.component.page-selector .page-selector-item-next {
  flex-grow: 1;
}

@media (max-width: 1199px) {
  .component.page-selector .page-selector-item-previous a,
  .component.page-selector .page-selector-item-next a {
    font-size: 0px;
  }
  .component.page-selector .page-selector-item-previous a:before, .component.page-selector .page-selector-item-previous a:after,
  .component.page-selector .page-selector-item-next a:before,
  .component.page-selector .page-selector-item-next a:after {
    font-size: 10px;
    position: relative;
    top: 11px;
  }
}

.component.page-selector .page-selector-item-previous {
  text-align: left;
}

.component.page-selector .page-selector-item-previous a:before {
  content: url("data:image/svg+xml; utf8, <svg  xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='18px'><path fill-rule='evenodd'  fill='rgb(116, 116, 116)' d='M0.006,9.003 L7.020,18.001 L8.001,16.962 L1.796,9.001 L8.001,1.041 L7.020,0.002 L0.006,9.000 L0.010,9.001 L0.006,9.003 Z'/></svg>");
  margin-right: 0.7em;
}

.component.page-selector .page-selector-item-next {
  text-align: right;
}

.component.page-selector .page-selector-item-next a:after {
  content: url("data:image/svg+xml; utf8, <svg  xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='18px'> <path fill-rule='evenodd'  fill='rgb(116, 116, 116)' d='M7.993,8.995 L0.980,-0.002 L-0.001,1.037 L6.204,8.997 L-0.001,16.957 L0.980,17.996 L7.993,8.998 L7.990,8.997 L7.993,8.995 Z'/></svg>");
  margin-left: 0.8em;
}
